import Vue from 'vue'
import App from './App.vue'
import dateFormat from './libs/dateFormat';
import Templates from './libs/template'
import router from './router';
import store from './store';
import config from './config';
import api from './api';
import tool from "./utils/tools";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import JSEncrypt from 'jsencrypt'
import {
	Base64
} from 'js-base64'
import VueCookies from 'vue-cookies'


// 计算并设置根元素的字体大小
function setRem() {
	const scale = document.documentElement.clientWidth / 1920;
	let num = 30;
	if (document.documentElement.clientWidth > 1079) {
		num = 20;
		document.documentElement.style.fontSize = `80px`
		// document.documentElement.style.fontSize = `${num * 4 * Math.min(scale, 1)}px`	
	} else {
		num = 48;
		document.documentElement.style.fontSize = `${num * 4 * Math.min(scale, 1)}px`
	}
	// const rem = document.documentElement.clientWidth / 10;
	// document.documentElement.style.fontSize = 39 + 'px';

	// document.documentElement.style.fontSize = `${num * 4 * Math.min(scale, 1)}px`
}

// 初始化时调用一次
setRem();

// 监听窗口大小变化时重新计算
window.addEventListener('resize', setRem);





Vue.config.productionTip = false
Vue.prototype.$dateFormat = dateFormat;
Vue.prototype.$config = config;
Vue.prototype.$api = api;
Vue.prototype.$jsEncrypt = JSEncrypt
Vue.prototype.$Base64 = Base64
Vue.use(VueCookies);
Vue.use(tool);
Vue.use(Templates)
Vue.use(ElementUI);
new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app')