export default API => ({
	//首页
	orderindex(params) {
		return API({
			url: `/index/Index/index`,
			method: 'POST'
		})
	},
	//客服
	getSet(params) {
		return API({
			url: `/index/Index/getSet`,
			method: 'POST'
		})
	},
	//注册
	register(params) {
		return API({
			url: `/index/Login/register`,
			data: params,
			method: 'POST'
		});
	},
	//登录
	logins(params) {
		return API({
			url: `/index/Login/login`,
			data: params,
			method: 'POST'
		});
	},
	//代理设置接口
	discount(params) {
		return API({
			url: `/index/Index/discount`,
			data: params,
			method: 'POST'
		});
	},
	//支付
	payment(params) {
		return API({
			url: `/index/Pay/payment`,
			data: params,
			method: 'POST'
		});
	},
	//支付
	center(params) {
		return API({
			url: `/index/User/center`,
			data: params,
			method: 'POST'
		});
	},
	//订单
	orderlist(params) {
		return API({
			url: `/index/User/orderlist`,
			data: params,
			method: 'POST'
		});
	},
	//订单详情
	orderDetail(params) {
		return API({
			url: `/index/User/orderDetail`,
			data: params,
			method: 'POST'
		});
	},
	//弹窗
	tanChu(params) {
		return API({
			url: `/index/User/tanchu`,
			data: params,
			method: 'POST'
		});
	},
	//确定
	istanChu(params) {
		return API({
			url: `/index/User/istanchu`,
			data: params,
			method: 'POST'
		});
	}
})