<template>
	<div>
		<!-- <theHeader v-if="$route.meta.login && !$route.meta.hideHeader"/> -->
		<div class="mainContent" v-if="$route.meta.showTitle">{{$route.meta.title}}</div>

		<keep-alive>
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
		<!-- <theFooter v-if="$route.meta.login && !$route.meta.hideHeader"/> -->
		<!-- <theBackTop /> -->
	</div>
</template>
<script>
	// import { mapActions } from "vuex";
	import theHeader from "./layout/header.vue";
	import theFooter from "./layout/footer.vue";
	import {
		mapActions
	} from "vuex";
	export default {
		components: {
			theHeader,
			theFooter,
		},
		name: "app",
		created() {
			let role = localStorage.getItem('role');
			if (role == 'teacher') {
				// this.getTeacherInfo();
			} else {
				// this.getUserInfo();

			}
		},
		methods: {
			// ...mapActions(["getUserInfo",'getTeacherInfo']),
		},
	};
</script>
<style>
	page {
		overflow-x: hidden
	}

	@import url('./assets/css/index.css');

	.el-avatar>img {
		display: block;
		height: 100%;
		width: 100%;
		vertical-align: middle;
	}

	.line-through {
		text-decoration: line-through;
		margin-right: 10px;
	}

	.text-color-9 {
		color: #999;
	}

	div {
		word-break: break-all;
	}

	.el-message-box {
		width: 8.23387097rem !important;
		margin: 0.16129032rem auto 0.82258065rem !important;
	}

	.el-dialog {
		width: 90% !important;
	}

	.el-message {
		width: auto !important;
		/* margin: 0.16129032rem auto 0.82258065rem !important; */
	}
</style>