const routerArr = [{
		path: '/',
		name: 'home',
		meta: {
			title: '首页',
			login: true
		},
		component: () => import( /* webpackChunkName: 'exam' */ './page/homePage.vue'),
	},
	{
		path: '/vip',
		name: 'vip',
		meta: {
			title: '列表',
			login: true
		},
		component: () => import( /* webpackChunkName: 'exam' */ './page/vip.vue'),
	},
]
export default routerArr