
export default {
    state: {
        pageList: [],
        settings: [],
    },

    getters: {
        // storeGetPageConfByName: (state) => (name) => {
        //     return state.pageList.find(
        //         page => page.pageName === name,
        //     );
        // },
        // storeGetBlockSettings: (state) => (block) => {
        //     return state.settings.find(
        //         item => item.blockInstanceId === block.id,
        //     );
        // },
    },

    mutations: {
        storeSetPageList(state, pageList) {
            state.pageList = pageList;
        },
        storeSetPageSettings(state, settings) {
            state.settings = settings;
        },
    },

    actions: {
    },
};
