
import callAPI from '../libs/callAxios'
import API from './../libs/axios'

let apiArr = {
    // 示例
    // Interface ({ data, params }) {
    //   return API({
    //     method: 'post', // 请求方式,不传默认get
    //     url: '', // 请求路径，接口地址
    //     timeout: 5000, // 请求超时, 不传默认10秒
    //     headers: {}, // header体属性, 不传默认 任何请求方式都支持 body和url 传参
    //     data, // body参数, 格式 data: { key: value, ... }
    //     params // url参数，格式 params: { key: value, ... }
    //   })
    // }
}
const Routers = require.context('./modules/', true, /\.js$/)
Routers.keys().forEach(key => {

    let urlArr = key.split("/");
    let apiKey=urlArr[urlArr.length-1].split(".")[0]

    apiArr[apiKey] = {
        ...apiArr,
        ...Routers(key).default(API)
    }
    // 轮训操作
    if(apiKey === 'call'){
        apiArr[apiKey] = {
            ...apiArr,
            ...Routers(key).default(callAPI),
        }
    }
})
export default {
    ...apiArr
}
