<template>
    <div class="footer-wrap">
      <div class="footer-cont">
        <div class="left">
            <div class="info">版权所有：河南森兰电子科技有限公司</div>
            <div class="icp">豫ICP备2020036813号-2</div>
        </div>
        <div class="right">
              <img class="qrcode" src="" slot="">
              <div class="">扫一扫关注 稚龙伴学</div>
        </div>
      </div>
        
    </div>
  </template>
  
  <script>
  export default {
    name: "the-footer",
    data() {
      return {
        
      }
    },
  };
  </script>
  
  <style scoped>
  .footer-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1440px;
    margin: 0 auto;
    height: 200px;
  }
  .footer-wrap {
    position: relative;
    z-index: 10;
    height: 200px;
    background: #282828;
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
  }
  .left{
    
  }
  .right{
    text-align: center;
  }
  .icp{
    margin-top: 30px;
  }
  .qrcode{
    width: 95px;
    height: 95px;
    background: #f5f5f5;
    margin-bottom: 15px;
  }
  </style>
  