<template>
	<div class="sec-wrap">
		<div class="mainContent flex flex-between flex-item-center">
			<div class="flex flex-start flex-item-center">
				<div class="logo">
					<img src="@/assets/images/logo.png" />
				</div>
				<div class="navBox flex flex-start flex-item-center">
					<template v-for="(item,index) in navArr">
						<div @click="checkNav(index)" :key="index" :class="['navItem',{'active':active==index}]">
							<div>
								{{item}}
							</div>
							<div class="activeLine"></div>

							<el-badge v-if="index==5" value="12" class="item"></el-badge>
						</div>

					</template>

				</div>
			</div>

		</div>
	</div>
</template>

<script>
	//   import loginModal from "../../login/loginModal.vue";
	//   import { mapGetters, mapMutations } from "vuex";
	export default {
		name: "sec-header",
		data() {

			return {
				active: 0,
				navArr: [
					'作业中心',
					'考试测验',
					'精准学情',
					'备课广场',
					'校本资源',
					'家校通知',
				]
			}
		},
		methods: {
			checkNav(e) {
				this.active = e;
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/ .el-badge__content {
		background-color: #E34D59;
		font-size: 10px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 16px;
	}

	.sec-wrap {
		background: #fff;
		height: 64px;
		background: #FFFFFF;
		box-shadow: 0px 4px 8px 0px rgba(11, 64, 53, 0.1);
	}

	.mainContent {
		.logo {
			width: 247px;
			margin-right: 53px;

			img {
				width: 100%;
				height: auto;
			}
		}

		.navBox {
			font-size: 16px;
			font-weight: 400;
			color: #666666;

			.navItem {
				margin-right: 43px;
				cursor: pointer;
				height: 64px;
				box-sizing: border-box;
				padding-top: 21px;
				line-height: 22px;
				position: relative;

				.item {
					position: absolute;
					right: -20px;
					top: 10px;
				}
			}

			.navItem:hover {
				color: #09B08C;
			}

			.active {
				color: #09B08C;

				.activeLine {
					position: absolute;
					bottom: 0px;
					margin: 0 auto;
					width: 34px;
					height: 2px;
					left: 0;
					right: 0;
					background: #09B08C;
					border-radius: 1px;
				}
			}
		}

		.avater {
			height: 64px;
			cursor: pointer;

			.avaterImg {
				width: 36px;
				height: auto;
				margin-right: 6px;
			}

			.downImg {
				width: 16px;
				height: auto;
				margin-left: 6px;
			}
		}

		// img{
		//   width: 100%;
		//   height: auto;
		// }
	}

	.margin-center {
		margin: 0 auto;
		text-align: center;
		width: 100%;
	}
</style>