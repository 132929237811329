const routerArr = [{
	path: '/login',
	name: 'login',
	meta: {
		title: '登录',
		login: false
	},
	component: () => import( /* webpackChunkName: 'exam' */ './page/login.vue'),
}, {
	path: '/register',
	name: 'register',
	meta: {
		title: '登录',
		login: false
	},
	component: () => import( /* webpackChunkName: 'exam' */ './page/register.vue'),
}, ]
export default routerArr