export default {
    namespaced: true,
    state: {
      // 页面文档可视高度(随窗口改变大小)
      documentClientHeight: 0,
      // 导航条, 布局风格, defalut(默认) / inverse(反向)
      navbarLayoutType: 'default',
      // 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
      sidebarLayoutSkin: 'dark',
      // 侧边栏, 折叠状态
      sidebarFold: false,
      // 侧边栏, 菜单
      menuList: [],
      menuActiveName: '',
      // 内容, 是否需要刷新
      contentIsNeedRefresh: false,
      // 主入口标签页
      mainTabs: [],
      mainTabsActiveName: '',
      blankId: JSON.parse(sessionStorage.getItem('userInfo') || '{}').bankId || 4,//学科id
      blankName: JSON.parse(sessionStorage.getItem('userInfo') || '{}').bankName || '数学',
      subjectId: 3,//学科id
      subjectName: '数学',
      defaultActiveIndex: localStorage.getItem("index") || '/',
      blanks: '初中数学',
    },
    mutations: {
      updateDocumentClientHeight(state, height) {
        state.documentClientHeight = height
      },
      updateNavbarLayoutType(state, type) {
        state.navbarLayoutType = type
      },
      updateSidebarLayoutSkin(state, skin) {
        state.sidebarLayoutSkin = skin
      },
      updateSidebarFold(state, fold) {
        state.sidebarFold = fold
      },
      updateMenuList(state, list) {
        state.menuList = list
      },
      updateMenuActiveName(state, name) {
        state.menuActiveName = name
      },
      updateContentIsNeedRefresh(state, status) {
        state.contentIsNeedRefresh = status
      },
      updateMainTabs(state, tabs) {
        state.mainTabs = tabs
      },
      updateMainTabsActiveName(state, name) {
        state.mainTabsActiveName = name
      },
      updateDefaultActiveIndex(state, name) {
        state.defaultActiveIndex = name
        window.localStorage.setItem("index", name);
      },
      updateBlankId(state, id) {
        state.blankId = id
        console.log(id)
      },
      updateBlanks(state, name) {
        state.blanks = name
        // console.log(name)
      },
      updateBlankName(state, name) {
        state.blankName = name
      },
      updateSubjectId(state, id) {
        state.subjectId = id;
      },
      updateSubjectName(state, name) {
        state.subjectName = name;
      },
    }
  }