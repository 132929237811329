import Axios from 'axios'
// import URL from '../config/url'
const isPro = Object.is(process.env.NODE_ENV, 'production')
// 生产地址
// import { Message } from 'element-ui'
// const baseURL = URL.API
const baseURL = isPro ? process.env.VUE_APP_SERVER : '/aaa'
const service = Axios.create({
	withCredentials: false,
	baseURL
})

// 请求拦截器
service.interceptors.request.use(config => {
	localStorage.getItem('token') && (config.headers['token'] = localStorage.getItem('token'))
	localStorage.getItem("organ") && (config.headers['organ'] = localStorage.getItem("organ"))
	localStorage.getItem("actor") && (config.headers['actor'] = localStorage.getItem("actor"))
	localStorage.getItem('branch') && (config.headers['branch'] = localStorage.getItem('branch'))
	return config
}, err => {
	return Promise.reject(err)
})

// 响应拦截器
service.interceptors.response.use(response => {
	return response
}, err => {
	console.log('出错了~~', err)
	return Promise.reject(err)
})

function callAPI(config) {
	const {
		url = '',
			data = null,
			params = null,
			headers = {
				"content-type": "application/json"
			},
			method = 'get',
			timeout = 300000
	} = config || {}
	return new Promise((resolve, reject) => {
		service({
			method, // 请求方式
			url, // 请求路径
			timeout, // 请求超时
			headers, // header体
			data, // body参数
			params //  URL参数
		}).then(res => {
			console.log(11111);
			resolve({
				...res.data,
				headers: res.headers
			})
		}).catch(err => {
			reject(err)
		})
	})
}
export default callAPI