<template>
  <div>
    <div class="title-tabs">
      <div class="overallLeftstar">
        <span class="title-tabs_title" v-if="title">
          {{ title ? title + ":" : "" }}
        </span>
        <div
          ref="tabse"
          class="card-tab selfTab box"
          :style="{ height: showMore ? 'auto' : height + 'px' }"
        >
          <span
            v-for="(item, index) in dataList"
            :key="index"
            :class="tabsIndex == index ? 'spans active' : 'spans'"
            @click="toggleTabs(index, item)"
            >{{ item.regionName }}</span
          >
        </div>
      </div>
      <div style="width: 60px">
        <span
          v-if="isLongContent"
          class="overallRight btn"
          @click="foldEvent()"
        >
          <p class="tips">{{ !showMore ? "展开" : "折叠" }}</p>
          <img
            v-if="!showMore"
            class="img"
            src="https://zhilongbanxue-123-1305079826.cos.ap-beijing.myqcloud.com/20221125/IconUp.png"
          />
          <img
            v-else
            class="img"
            src="https://zhilongbanxue-123-1305079826.cos.ap-beijing.myqcloud.com/20221125/IconDown.png"
          />
        </span>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "title-tabs",
  data() {
    return {
    foldShow:false,
      showMore: true, //展示按钮
      isLongContent: true,
      tabsIndex: 0,
      height: 32,
    };
  },
  props: {
    title: String,
    dataList: Array,
    infolist: Array,
  },
  mounted() {
    this.$nextTick(() => {
      let content = this.$refs.tabse.offsetHeight;
      // console.log(this.title,content);
      if (content > this.height) {
        this.isLongContent = true;
        let that = this;
        setTimeout(() => {
            that.showMore = false;
        }, 1);
      } else {
        this.isLongContent = false;
      }
    });
  },
  methods: {
    toggleTabs(index, item) {
      this.tabsIndex = index;
      this.$emit("onChange", item);
    },
    foldEvent() {
      this.showMore = !this.showMore;
      // console.log(this.showMore);
    },
  },
};
</script>
  <style scoped lang='scss'>
.selfTab {
  span {
    margin: left 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.title-tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.overallLeftstar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.title-tabs_title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-top: 5px;
  width: 50px;
}
.box {
  overflow: hidden;
}
.img {
  text-align: right;
  width: 16px;
}
.tips {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #09b08c;
}
.btn {
  width: 60px;
  height: 22px;
  margin-top: 5px;
}
.card-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: calc(100% - 50px);
  margin-top: 0px;
  .spans {
    background: rgba(255, 255, 255, 0.38);
    border-radius: 4px;
    padding: 5px 14px;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;

    &.active {
      color: #09b08c;
      background: rgba(9, 176, 140, 0.1);
      border-radius: 5px;
    }
  }
}
</style>
  