import Axios from 'axios'
// import URL from '../config/url'
const isPro = Object.is(process.env.NODE_ENV, 'production')
console.log(process.env, 'process.env.NODE_ENV');
// 生产地址

// import { Message } from 'element-ui'
import resetMessage from './resetMessage'
// const baseURL = URL.API
// const baseURL = process.env.VUE_APP_SERVER;
const baseURL = "https://www.taida58.xyz/api"
// const baseURL = "http://api.taida58.xyz/"
// const baseURL = isPro ? process.env.VUE_APP_SERVER : '/api'
const service = Axios.create({
	withCredentials: false,
	baseURL
})


// 请求拦截器
service.interceptors.request.use(
	(config) => {
		sessionStorage.getItem('token') && (config.headers['token'] = sessionStorage.getItem('token'))
		config.headers['source-host'] = 'pc'
		return config
	},
	(err) => {
		console.error(err)
		//   return Promise.reject(err)
	}
)

// 响应拦截器
service.interceptors.response.use(
	(config) => {
		return config
	},
	(err) => {
		console.error(err)
		//   return Promise.reject(err)
	}
)
/* eslint-disable */

function API(config) {
	const {
		url = 'https://api.taida58.xyz', data = null, params = null, headers = {
			'content-type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Access-control-allow-credentials': true,
			'Access-Control-Allow-Headers': 'access-token,access_token,Content-Type,x-token',

		}, method = 'POST', timeout = 300000
	} = config || {}
	return new Promise((resolve, reject) => {
		service({
				method, // 请求方式
				url, // 请求路径
				timeout, // 请求超时
				headers, // header体
				data, // body参数
				params, //  URL参数
			})
			.then((res) => {
				if (res.data.code && res.data.code !== 1) {
					if (+res.data.code === 404) {
						window.location = `https://api.taida58.xyz/README.md`
						localStorage.clear()
						sessionStorage.clear()
					}
				}
				resolve({
					...res.data,
					headers: res.headers,
				})
			})
			.catch((err) => {
				//   reject(err)
				console.error(err)
				if (err && err.message) {
					if (err.message === 'Network Error') {
						resetMessage({
							showClose: true,
							message: '网络异常',
							type: 'error',
						})
					}
					if (err.message.indexOf('timeout') !== -1) {
						resetMessage({
							showClose: true,
							message: '网络超时',
							type: 'error',
						})
					}
				}
			})
	})
}

export default API