
const routerArr = [
    {
        path: '/minePage',
        name: 'minePage',
        meta: { title:'我的' , login:true },
        component: () => import(/* webpackChunkName: 'exam' */ './page/minePage.vue'),
    },
]
export default routerArr
