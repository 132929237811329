import Vue from 'vue';
import Vuex from 'vuex';
import app from './module/app';
import pages from './module/pages';
import user from './module/user';
import common from './module/common';
//引入
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //
        theme:localStorage.getItem('theme'),
    },
    mutations: {
        setSaasFlag(state, val){
            state.isNewSaas = val
        },
        //主题
        setTheme(state, payload){
            state.theme = payload
        },
    },
    actions: {
    },
    getters: {
    },
    modules: {
        app,
        pages,
        user,
        common
    },
});
