<template>
    <div
      class="title-tabs">
      <span class="title-tabs_title" v-if="title">
        {{ title ? title+':' : "" }}
      </span>
      <div class="card-tab selfTab" style="margin-top: 0">
        <span
          v-for="(item, index) in dataList"
          :key="index"
          :class="tabsIndex == index ? 'active' : ''"
          @click="toggleTabs(index,item)"
          >{{ item.regionName }}</span
        >
      </div>
       <!-- <div class="card-tab selfTab" style="margin-top: 0">
        <span
          v-for="(item, index) in infolist"
          :key="index"
          :class="tabsIndex == index ? 'active' : ''"
          @click="toggleTable(index,item)"
          >{{ item.name }}</span
        >
      </div> -->
    </div>
  </template>
  <script>
  export default {
    name: "title-tabs",
    data() {
      return {
        tabsIndex: 0,
      };
    },
    props: {
      title: String,
      dataList: Array,
      infolist:Array,
    },
    mounted() {},
    methods: {
      toggleTabs(index,item) {
        this.tabsIndex = index;
        this.$emit("on-change", item);
      },
        toggleTable(index,item) {
        this.tabsIndex = index;
        this.$emit("on-changeInfo", item);
      }, 
    },
  };
  </script>
  <style scoped lang='scss'>
  .selfTab {
    span {
      margin: left 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .title-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &_title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-right: 18px;
    }
  }
  .card-tab {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    span {
      background: rgba(255, 255, 255, 0.38);
      border-radius: 4px;
      padding: 5px 14px;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      &.active {
        color: #09B08C;
        background: rgba(9,176,140,0.1);
        border-radius: 5px;
      }
    }
  }
  </style>
  