import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
Vue.use(Router)

function getAbsolutePath() {
	let path = location.pathname
	return path.substring(0, path.lastIndexOf('/') + 1)
}
const router = new Router({
	mode: 'hash',
	routes,
	base: getAbsolutePath(),
})
export default router