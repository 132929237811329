export default {
  //namespaced: true,
  state: {
    userInfo: JSON.parse(sessionStorage.getItem("userInfo") || '{}') || {},
  },
  mutations: {
    // updateId(state, id) {
    // 	state.id = id
    // },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      console.log(userInfo);
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
  },
  actions: {
    updateUserInfo({ commit }, data) {
      commit("updateUserInfo", data);
    },
    // updatePhone ({ commit },data) {
    // 	commit('updatePhone',data)
    //   },
    // updateOrgName ({ commit },data) {
    // 	commit('updateOrgName',data)
    //   },
    // updateDepart ({ commit },data) {
    // 	commit('updateDepart',data)
    //   }
  },
};
